var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "bill-list" },
    [
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "li",
          { key: index },
          [
            _c("BillDetail", {
              ref: "detail",
              refInFor: true,
              attrs: { item: item, month: _vm.month },
              on: { lookBill: _vm.lookBill, importBill: _vm.importBill },
            }),
          ],
          1
        )
      }),
      _vm.list.length == 0
        ? _c("div", { staticClass: "empty-data" }, [
            _c("img", {
              style: "width:126px",
              attrs: { src: require("@/assets/img/home/noData.png"), alt: "" },
            }),
            _c("div", [_vm._v("暂无数据，请创建账单")]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }